import React from 'react';
import { useTranslate } from '../../../_metronic/utils/customHooks';

export default function ContactAdmin() {
  const t = useTranslate();
  return (
    <>
      <div className="kt-login__head" />
      <div className="kt-login__body">
        <div className="kt-login__form">
          <div className="kt-login__title">
            <h3 style={{ whiteSpace: 'pre-wrap' }}>
              {t('AUTH.CONTACT_ADMIN')}
            </h3>
          </div>
        </div>
      </div>
    </>
  );
}
