import React, { useCallback, useState } from 'react';
import { useToast, useTranslate } from '../../../_metronic/utils/customHooks';
import SeePass from './icons/SeePass';
import DoNotSeePass from './icons/DoNotSeePass';

import '../../../_metronic/_assets/sass/pages/login/login-1.scss';
import { getToken } from '../../crud/users/user.crud';

export default function Login() {
  const t = useTranslate();
  const toast = useToast();
  const [showError, setShowError] = useState({ password: false, email: false });

  const handleLogin = useCallback(async credentials => {
    try {
      const result = await getToken(credentials);
      if (result.token) {
        window.location.href = '/auth/authenticate';
      } else if (result.user) {
        try {
          window.location.href = `/auth/otp?user=${result.user}`;
        } catch {
          toast.error('login.requestOTPToken.error');
        }
      }
    } catch (err) {
      toast.error('login.emailOrPasswordIncorrect');
      setShowError({ password: true, email: true });
    }
  }, []);
  const [inputValues, setInputValues] = useState({
    email: '',
    password: '',
  });

  const [dontSeePassword, setSeePassword] = useState<boolean>(true);

  const handleOnChange = useCallback(
    event => {
      const { name, value } = event.target;
      if (value !== '') {
        setShowError({ ...showError, [name]: false });
      } else {
        setShowError({ ...showError, [name]: true });
      }
      setInputValues({ ...inputValues, [name]: value });
    },
    [inputValues],
  );
  const handleKeypress = (event: any) => {
    if (event.key === 'Enter') {
      handleLogin(inputValues).then(r => r);
    }
  };

  return (
    <>
      <div className="kt-login__head" />
      <div className="kt-login__body">
        <div className="kt-login__form">
          <div className="kt-login__title">
            <h3>{t('login.welcome')}</h3>
            <span className="kt-login__subtitle">{t('login.subtitle')}</span>
          </div>
          <div className="kt-login__input">
            <div className={`login-email ${showError.email && 'error'}`}>
              <label htmlFor="email">{t('login.email')}</label>
              <input
                id="email"
                type="text"
                className="form-control control-height"
                name="email"
                value={inputValues.email}
                placeholder={t('login.email.placeholder')}
                onChange={handleOnChange}
                autoComplete="off"
              />
            </div>
            <div className={`login-password ${showError.password && 'error'}`}>
              <label htmlFor="password">{t('login.password')}</label>
              <input
                id="password"
                type={dontSeePassword ? 'password' : 'text'}
                className="form-control control-height"
                name="password"
                value={inputValues.password}
                placeholder={t('login.password.placeholder')}
                onChange={handleOnChange}
                autoComplete="off"
                onKeyPress={handleKeypress}
              />
              <button
                type="button"
                className="btn p-viewer"
                onClick={() => setSeePassword(!dontSeePassword)}
              >
                {dontSeePassword ? <DoNotSeePass /> : <SeePass />}
              </button>
            </div>
            <button
              id="kt_login_signin_submit"
              type="button"
              className="btn btn-primary col-4 btn-elevate kt-login__btn-primary"
              onClick={() => handleLogin(inputValues)}
            >
              {t('AUTH.LOGIN.BUTTON')}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
