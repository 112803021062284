import React from 'react';

function Delete() {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.3764 20.5279L18.1628 9.16544C18.6403 8.5527 18.8101 7.8443 18.6509 7.12299C18.513 6.46726 18.1097 5.84377 17.5049 5.37078L16.0299 4.19906C14.7459 3.17784 13.1541 3.28534 12.2415 4.45706L11.2546 5.73735C11.1273 5.89752 11.1591 6.13401 11.3183 6.26301C11.3183 6.26301 13.812 8.26246 13.8651 8.30546C14.0349 8.46671 14.1622 8.6817 14.1941 8.93969C14.2471 9.44493 13.8969 9.91792 13.377 9.98242C13.1329 10.0147 12.8994 9.93942 12.7297 9.79967L10.1086 7.71422C9.98126 7.61855 9.79025 7.63898 9.68413 7.76797L3.45514 15.8303C3.0519 16.3355 2.91395 16.9912 3.0519 17.6255L3.84777 21.0761C3.89021 21.2589 4.04939 21.3879 4.24039 21.3879L7.74222 21.3449C8.37891 21.3341 8.97316 21.0439 9.3764 20.5279ZM14.2797 19.4533H19.9898C20.5469 19.4533 21 19.9123 21 20.4766C21 21.0421 20.5469 21.5 19.9898 21.5H14.2797C13.7226 21.5 13.2695 21.0421 13.2695 20.4766C13.2695 19.9123 13.7226 19.4533 14.2797 19.4533Z"
        fill="#749e9e"
      />
    </svg>
  );
}

export default React.memo(Delete);
