import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, takeLatest } from 'redux-saga/effects';
import { actionTypes, actions } from './auth.actions';
import * as routerHelpers from '../../../router/RouterHelpers';

export { actionTypes, actions } from './auth.actions';

const initialAuthState = () =>
  process.env.REACT_APP_CONTROLLED_ENV ? { isLoggedIn: false } : {};

export const reducer = persistReducer(
  { storage, key: 'trusty-auth', whitelist: ['user', 'isLoggedIn'] },
  (state = initialAuthState(), action) => {
    switch (action.type) {
      case actionTypes.Login: {
        return { isLoggedIn: true, user: undefined };
      }

      case actionTypes.Logout: {
        routerHelpers.forgotLastLocation();
        return initialAuthState();
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload;

        if (
          !process.env.REACT_APP_CONTROLLED_ENV &&
          (!user || !user.role || !user.role.role || !user.role.role.name)
        ) {
          window.location.href = '/auth/contactAdmin';
        }

        return { ...state, user };
      }

      default:
        return state;
    }
  },
);

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser());
  });
}
