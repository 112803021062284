import React, {
  CSSProperties,
  ForwardedRef,
  forwardRef,
  PropsWithChildren,
  ReactFragment,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import { isFragment } from 'react-is';
import clsx from 'clsx';

type GenericProps = {
  className?: string;
};

export const PortletHeaderIcon = forwardRef<HTMLSpanElement, GenericProps>(
  ({ className }: GenericProps, ref) => (
    <span ref={ref} className={clsx('kt-portlet__head-icon', className)} />
  ),
);

export const PortletHeaderTitle = forwardRef<
  HTMLHeadingElement,
  PropsWithChildren<GenericProps>
>(({ className, ...props }: GenericProps, ref) => (
  // eslint-disable-next-line jsx-a11y/heading-has-content
  <h3
    {...props}
    ref={ref}
    className={clsx('kt-portlet__head-title', className)}
  />
));

export const PortletHeaderToolbar = forwardRef<HTMLDivElement, GenericProps>(
  ({ className, ...props }: GenericProps, ref) => (
    <div
      {...props}
      ref={ref}
      className={clsx('kt-portlet__head-toolbar', className)}
    />
  ),
);

type PortletHeaderProps = {
  icon?: string;
  title?: string | ReactFragment;
  toolbar?: ReactNode;
  sticky?: boolean;
  labelRef?: ForwardedRef<HTMLDivElement>;
  isPageHeader?: boolean;
} & GenericProps;
export const PortletHeader = forwardRef<HTMLDivElement, PortletHeaderProps>(
  (
    {
      icon,
      title,
      toolbar,
      className,
      sticky = false,
      labelRef,
      isPageHeader = false,
    }: PortletHeaderProps,
    ref,
  ) => {
    const [top, setTop] = useState(0);
    const [windowHeight, setWindowHeight] = useState(0);

    useEffect(() => {
      function handleResize() {
        setWindowHeight(window.innerWidth);
      }

      handleResize();

      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    });

    useEffect(() => {
      // Skip if sticky is disabled or on initial render when we don't know about window height.
      if (!sticky || windowHeight === 0) {
        return;
      }

      const headerElement = document.querySelector('.kt-header') as HTMLElement;
      const subheaderElement = document.querySelector(
        '.kt-subheader',
      ) as HTMLElement;
      const headerMobileElement = document.querySelector(
        '.kt-header-mobile',
      ) as HTMLElement;

      let nextMarginTop = 0;

      // mobile header
      if (window.getComputedStyle(headerElement).height === '0px') {
        nextMarginTop = headerMobileElement.offsetHeight;
      } else if (
        document.body.classList.contains('kt-header--minimize-topbar')
      ) {
        // hardcoded minimized header height
        nextMarginTop = 60;
      } else {
        // normal fixed header
        if (document.body.classList.contains('kt-header--fixed')) {
          nextMarginTop += headerElement.offsetHeight;
        }

        if (document.body.classList.contains('kt-subheader--fixed')) {
          nextMarginTop += subheaderElement.offsetHeight;
        }
      }

      setTop(nextMarginTop);
    }, [sticky, windowHeight]);

    return (
      <div
        ref={ref}
        className={clsx('kt-portlet__head', { 'page-header': isPageHeader })}
        style={
          !sticky
            ? undefined
            : { top, position: 'sticky', backgroundColor: '#fff' }
        }
      >
        {title != null && (
          <div
            ref={labelRef}
            className={clsx('kt-portlet__head-label', className)}
          >
            {icon}

            {
              /* Wrap string and fragments in PortletHeaderTitle */
              typeof title === 'string' || isFragment(title) ? (
                <PortletHeaderTitle>{title}</PortletHeaderTitle>
              ) : (
                title
              )
            }
          </div>
        )}

        {toolbar}
      </div>
    );
  },
);

type PortletBodyProps = {
  fit?: boolean;
  fluid?: boolean;
  style?: CSSProperties;
} & GenericProps;
export const PortletBody = forwardRef<
  HTMLDivElement,
  PropsWithChildren<PortletBodyProps>
>(({ fit, fluid, className, ...props }: PortletBodyProps, ref) => (
  <div
    {...props}
    ref={ref}
    className={clsx(
      'kt-portlet__body',
      {
        'kt-portlet__body--fit': fit,
        'kt-portlet__body--fluid': fluid,
      },
      className,
    )}
  />
));

export const PortletFooter = forwardRef<
  HTMLDivElement,
  PropsWithChildren<GenericProps>
>(({ className, ...props }: GenericProps, ref) => (
  <div {...props} ref={ref} className={clsx('kt-portlet__foot', className)} />
));

type PortletProps = {
  fluidHeight?: boolean;
} & GenericProps;
export const Portlet = forwardRef<
  HTMLDivElement,
  PropsWithChildren<PortletProps>
>(({ fluidHeight, className, ...props }: PortletProps, ref) => (
  <div
    {...props}
    ref={ref}
    className={clsx(
      'kt-portlet',
      { 'kt-portlet--height-fluid': fluidHeight },
      className,
    )}
  />
));

// Set display names for debugging.
if (process.env.NODE_ENV !== 'production') {
  Portlet.displayName = 'Portlet';

  PortletHeader.displayName = 'PortletHeader';
  PortletHeaderIcon.displayName = 'PortletHeaderIcon';
  PortletHeaderTitle.displayName = 'PortletHeaderTitle';
  PortletHeaderToolbar.displayName = 'PortletHeaderToolbar';

  PortletBody.displayName = 'PortletBody';
  PortletFooter.displayName = 'PortletFooter';
}
