export enum PlatformRoleName {
  USER = 'User',
  ADVANCED_USER = 'AdvancedUser',
  ADMIN = 'Admin',
}

export enum SectionName {
  FUND_MASTER_FILE = 'Fund Master File',
  FUND_PROJECTS = 'Fund Projects',
  USER_MANAGEMENT = 'User Management',
  DATA_MANAGEMENT = 'Data Management',
  REPORTS = 'Reports',
  EXPORTS = 'Exports',
  ADMIN_PANEL = 'Admin Panel',
  IP = 'IP',
}

/*
Category            Admin    User   Advanced User
Fund Master File    R/W/D    R/W    R/W/D
Fund Projects       R/W/D    R/W    R/W/D
User Management     R/W/D           Limited, special case (check: https://mobilu.atlassian.net/browse/AU-131)
Data Management     R/W/D           R/W/D
Reports             R        R      R
Exports             R
 */

// using unix style bitwise permission notation, read = 4, write = 2, delete = 1
export enum AccessRights {
  READ = 4,
  WRITE = 2,
  DELETE = 1,
  NONE = 0,
}

export type RoleAccessRights = Record<SectionName, number>;
export const SiteAccessRights: Record<PlatformRoleName, RoleAccessRights> = {
  [PlatformRoleName.ADMIN]: {
    [SectionName.FUND_MASTER_FILE]:
      AccessRights.READ | AccessRights.WRITE | AccessRights.DELETE,
    [SectionName.FUND_PROJECTS]:
      AccessRights.READ | AccessRights.WRITE | AccessRights.DELETE,
    [SectionName.USER_MANAGEMENT]:
      AccessRights.READ | AccessRights.WRITE | AccessRights.DELETE,
    [SectionName.DATA_MANAGEMENT]:
      AccessRights.READ | AccessRights.WRITE | AccessRights.DELETE,
    [SectionName.REPORTS]: AccessRights.READ,
    [SectionName.EXPORTS]: AccessRights.READ,
    [SectionName.ADMIN_PANEL]:
      AccessRights.READ | AccessRights.WRITE | AccessRights.DELETE,
    [SectionName.IP]:
      AccessRights.READ | AccessRights.WRITE | AccessRights.DELETE,
  },
  [PlatformRoleName.ADVANCED_USER]: {
    [SectionName.FUND_MASTER_FILE]:
      AccessRights.READ | AccessRights.WRITE | AccessRights.DELETE,
    [SectionName.FUND_PROJECTS]:
      AccessRights.READ | AccessRights.WRITE | AccessRights.DELETE,
    [SectionName.USER_MANAGEMENT]:
      AccessRights.READ | AccessRights.WRITE | AccessRights.DELETE,
    [SectionName.DATA_MANAGEMENT]:
      AccessRights.READ | AccessRights.WRITE | AccessRights.DELETE,
    [SectionName.REPORTS]: AccessRights.READ,
    [SectionName.EXPORTS]: AccessRights.NONE,
    [SectionName.ADMIN_PANEL]:
      AccessRights.READ | AccessRights.WRITE | AccessRights.DELETE,
    [SectionName.IP]:
      AccessRights.READ | AccessRights.WRITE | AccessRights.DELETE,
  },
  [PlatformRoleName.USER]: {
    [SectionName.FUND_MASTER_FILE]: AccessRights.READ | AccessRights.WRITE,
    [SectionName.FUND_PROJECTS]: AccessRights.READ | AccessRights.WRITE,
    [SectionName.USER_MANAGEMENT]: AccessRights.NONE,
    [SectionName.DATA_MANAGEMENT]: AccessRights.NONE,
    [SectionName.REPORTS]: AccessRights.READ,
    [SectionName.EXPORTS]: AccessRights.NONE,
    [SectionName.ADMIN_PANEL]:
      AccessRights.READ | AccessRights.WRITE | AccessRights.DELETE,
    [SectionName.IP]:
      AccessRights.READ | AccessRights.WRITE | AccessRights.DELETE,
  },
};
