import BaseModel from '../../base/models/BaseModel';

type License = BaseModel & {
  id: string;
  organizationName: string;
  key: string;
  expiry?: number;
  gapp: number;
  meeting: number;
  notes: number;
  lastUsed?: Date;
  lastIP?: string;
  organizationId: string;
  isPlatform: boolean;
  isBlocked?: boolean;
  lastMailForIp?: Date;
  lastMailForUserCount?: Date;
  lastMailForExpiry?: Date;
  lastMailForOrgaChange?: Date;
};

export type LicenseEdit = Partial<License>;

export const serializeLicense = (license: Partial<License>): object =>
  Object.fromEntries(
    Object.entries(license).map(([key, value]) => [
      key,
      value === '' ? undefined : value,
    ]),
  );

export default License;
