import {
  AccessRights,
  PlatformRoleName,
  SectionName,
  SiteAccessRights,
} from './PlatformRole';
// eslint-disable-next-line import/no-cycle
import BaseModel from '../../base/models/BaseModel';

export const RoleValues = {
  ADMIN: 'ADMIN',
  USER: 'USER',
};

export type Image = {
  name: string;
  medium: string;
  thumb: string;
  url: string | ArrayBuffer | null;
  height?: number;
  size?: number;
  width?: number;
};

export type Github = {
  enabled?: boolean;
  token?: string;
  username?: string;
};
export type Role = (typeof RoleValues)[keyof typeof RoleValues];

export type User = BaseModel & {
  id: string;
  name: string;
  firstName?: string;
  lastName?: string;
  login?: string;
  company: string[];
  role?: Role;
  email: string;
  image: Image;
  otpEnabled: boolean;
  github?: Github;
};

export const userAccessRights = (
  user?: User,
  section?: SectionName,
): number | undefined =>
  process.env.REACT_APP_CONTROLLED_ENV
    ? AccessRights.READ | AccessRights.WRITE | AccessRights.DELETE
    : SiteAccessRights[user?.role || PlatformRoleName.ADMIN][section!];

export const userCanReadInSection = (
  user?: User,
  section?: SectionName,
): boolean =>
  !!(
    (userAccessRights(user, section) || AccessRights.NONE) & AccessRights.READ
  );

export const userCanWriteInSection = (
  user?: User,
  section?: SectionName,
): boolean =>
  !!(
    (userAccessRights(user, section) || AccessRights.NONE) & AccessRights.WRITE
  );

export const userCanDeleteInSection = (
  user?: User,
  section?: SectionName,
): boolean =>
  !!(
    (userAccessRights(user, section) || AccessRights.NONE) & AccessRights.DELETE
  );

export const serializeUser = (user: Partial<User>): object =>
  Object.fromEntries(
    Object.entries(user).map(([key, value]) => [
      key,
      value === '' ? undefined : value,
    ]),
  );

export type UserEdit = Partial<User>;
