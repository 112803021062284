import React, {
  ForwardedRef,
  forwardRef,
  ReactNode,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react';
import {
  Column,
  useTable,
  useSortBy,
  usePagination,
  HeaderGroup,
  Row,
  Cell,
  SortingRule,
} from 'react-table';
import clsx from 'clsx';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import './list.scss';
import {
  useDidUpdateEffect,
  useToast,
} from '../../../../_metronic/utils/customHooks';
// @ts-ignore
import { ReactComponent as ArrowDownIcon } from './ArrowDown.svg';
import ListPagination from './ListPagination';
import APIResponse from '../../../crud/base/models/APIResponse';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import DraggableRow from './DraggableRow';

export type FetchListFn<T extends object> = (
  filters?: object,
  pagination?: PaginationSettings,
  sorting?: SortingSettings<T>,
  populate?: PopulateSettings<T>,
) => Promise<APIResponse<T[]>>;

export type PopulateSettings<T> = (keyof T)[];

export type PaginationSettings = {
  pageIndex: number;
  pageSize: number;
};

export type SortingSettings<T extends object> = SortingRule<T>[];

export type ListHandle = {
  forceRefresh: () => void;
  reset?: () => void;
};

export type ListTheme = 'primary' | 'secondary';

type ListProps<T extends object> = {
  columns: Column<T>[];
  filters?: object;
  fetchData?: FetchListFn<T>;
  data?: T[] | null;
  isLoading?: boolean;
  selectedRow?: T;
  idField?: keyof T;
  labelField?: keyof T;
  onRowSelected?: (row: Row<T>, cell?: Cell<T>) => void;
  onRowClicked?: (row: Row<T>, cell?: Cell<T>) => void;
  onRowMoved?: (dragIndex: number, hoverIndex: number) => void;
  disablePagination?: boolean;
  initialPageSize?: PageSizeOptions;
  initialSortBy?: SortingSettings<T>;
  theme?: ListTheme;
  id?: string;
  renderSubTable?: (row: T) => ReactNode;
};

type PageSizeOptions = 10 | 20 | 30 | 40 | 50;

// FIXME: Lots of ts-ignore needed because I suspect the type definition is not up to date,
//  or simply doesn't cover the entire API
const List = forwardRef<ListHandle, ListProps<any>>(
  <T extends object>(
    {
      theme = 'primary',
      filters = {},
      data: localData = null,
      isLoading: localIsLoading = false,
      fetchData,
      columns,
      // @ts-ignore
      idField = 'id',
      // @ts-ignore
      labelField = 'label',
      selectedRow,
      onRowSelected,
      onRowClicked,
      onRowMoved,
      disablePagination = false,
      initialPageSize = 20,
      initialSortBy = [],
      id,
      renderSubTable,
    }: ListProps<T>,
    ref: ForwardedRef<ListHandle>,
  ) => {
    const isLocalList = !!localData;

    const toast = useToast();
    const [isLoading, setIsLoading] = useState(false);
    const [{ data, total: totalItems }, setData] = useState<APIResponse<T[]>>({
      data: isLocalList ? localData || [] : [],
      total: isLocalList ? (localData || []).length : 0,
    });
    const [pageCount, setPageCount] = useState(
      isLocalList && !disablePagination
        ? Math.ceil((localData || []).length / initialPageSize)
        : 0,
    );

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      /* @ts-ignore */
      page,
      /* @ts-ignore */
      canPreviousPage,
      /* @ts-ignore */
      canNextPage,
      /* @ts-ignore */
      gotoPage,
      /* @ts-ignore */
      nextPage,
      /* @ts-ignore */
      previousPage,
      /* @ts-ignore */
      setPageSize,
      /* @ts-ignore */
      state: { pageIndex, pageSize, sortBy },
    } = useTable<T>(
      {
        data,
        columns,
        pageCount,
        initialState: {
          /* @ts-ignore */
          pageSize: disablePagination ? data.length : initialPageSize,
          sortBy: initialSortBy,
        },
        manualPagination: !isLocalList,
        manualSortBy: !isLocalList,
        autoResetPage: isLocalList && disablePagination,
        autoResetSortBy: isLocalList && disablePagination,
      },
      useSortBy,
      usePagination,
    );

    const handleFetching = useCallback(() => {
      if (!isLocalList && fetchData) {
        setIsLoading(true);
        fetchData(filters, { pageIndex, pageSize }, sortBy)
          .catch(err => {
            toast.errorFromAPI(err);
            return { data: [], total: 0 };
          })
          .then(response => {
            setPageCount(Math.ceil(response.total / pageSize || 0));
            setData(response);
            setIsLoading(false);
          });
      }
    }, [fetchData, filters, pageIndex, pageSize, sortBy]);

    useDidUpdateEffect(() => {
      handleFetching();
    }, [pageIndex, pageSize, sortBy]);

    useDidUpdateEffect(() => {
      if (localData) {
        setData({ data: localData, total: localData.length });
      }
    }, [localData]);

    useImperativeHandle(ref, () => ({
      forceRefresh: handleFetching,
      reset: () => {
        setPageCount(0);
        setData({ data: [], total: 0 });
        setIsLoading(false);
      },
    }));

    const sortIndicator = (column: HeaderGroup<T>): ReactNode => {
      /* @ts-ignore */
      if (!column.isSorted) {
        return <span className="indicator-space" />;
      }
      /* @ts-ignore */
      return column.isSortedDesc ? (
        <ArrowDownIcon />
      ) : (
        <ArrowDownIcon className="rotated" />
      );
    };

    // Turn off sorting on empty tables, so we don't make unnecessary requests
    const finalHeaderProps = (column: HeaderGroup<T>) =>
      totalItems > 0
        ? // @ts-ignore
          column.getHeaderProps(column.getSortByToggleProps())
        : column.getHeaderProps();

    const isClickable = !!(onRowSelected || onRowClicked || onRowMoved);
    const handleRowClicked = onRowSelected || onRowClicked || (() => null);

    const rowClassName = (row: Row<T>) =>
      clsx({
        'kt-list-body-row': true,
        selected: selectedRow && row.original[idField] === selectedRow[idField],
      });

    const finalLoading = isLoading || localIsLoading;
    const finalData: Array<Row<T>> =
      localData && !disablePagination ? page : rows;

    return (
      <div className="kt-list-container" id={id}>
        {finalLoading ? (
          <LoadingIndicator />
        ) : (
          <DndProvider backend={HTML5Backend}>
            <table
              {...getTableProps()}
              className={clsx('kt-list', `kt-list-${theme}`)}
            >
              <thead className="kt-list-header">
                {headerGroups.map(headerGroup => (
                  <tr
                    {...headerGroup.getHeaderGroupProps()}
                    className="kt-list-header-row"
                  >
                    {headerGroup.headers.map(column => (
                      <th
                        {...finalHeaderProps(column)}
                        className="kt-list-header-cell"
                      >
                        {column.render('Header')}
                        {sortIndicator(column)}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()} className="kt-list-body">
                {finalData.map((row, index) => {
                  prepareRow(row);
                  return (
                    <React.Fragment key={`${row.original[idField]}`}>
                      <DraggableRow
                        key={`${row.original[idField]}`}
                        index={index}
                        row={row}
                        labelField={labelField}
                        className={rowClassName(row)}
                        isClickable={isClickable}
                        onRowClicked={handleRowClicked}
                        onRowMoved={onRowMoved}
                      />
                      {renderSubTable && (
                        <tr>
                          <td colSpan={headerGroups[0].headers.length}>
                            {renderSubTable(row.original)}
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          </DndProvider>
        )}
        {!disablePagination && (
          <ListPagination
            gotoPage={gotoPage}
            canPreviousPage={canPreviousPage}
            previousPage={previousPage}
            nextPage={nextPage}
            canNextPage={canNextPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            totalItems={totalItems}
            pageIndex={pageIndex}
            pageCount={pageCount}
          />
        )}
      </div>
    );
  },
);

List.displayName = 'List';
export default List;
