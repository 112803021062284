import {
  DependencyList,
  EffectCallback,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import toast from 'react-hot-toast';
import { AxiosError } from 'axios';
import isEqual from 'lodash/isEqual';
import APIError from '../../app/crud/base/models/APIError';

export type TranslationParams = { [key: string]: string | number | undefined };
export type TranslationFn = (key: string, params?: TranslationParams) => string;

export function useTranslate(): TranslationFn {
  const intl = useIntl();
  return (key: string, data?: TranslationParams) =>
    intl.formatMessage({ id: key }, data);
}

export function useDidUpdateEffect(fn: EffectCallback, inputs: DependencyList) {
  const didMountRef = useRef(false);
  useEffect(() => {
    if (didMountRef.current) fn();
    else didMountRef.current = true;
  }, inputs);
}

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function useToast(entity?: string) {
  const t = useTranslate();

  const showError = (messageKey: string, params?: any) =>
    messageKey && toast.error(t(messageKey, params));
  const showSuccess = (messageKey: string, params?: any) =>
    messageKey && toast.success(t(messageKey, params));

  const entityTranslate = (e: string) => t(`ENTITY.${e.toUpperCase()}`);
  const itemName = entity
    ? entityTranslate(entity)
    : entityTranslate('generic');

  const itemOrOverride = (entityOverwrite?: string) =>
    entityOverwrite ? entityTranslate(entityOverwrite) : itemName;

  return {
    error: showError,
    errorFromAPI: (error: AxiosError<APIError>, entityOverwrite?: string) => {
      if (error?.response?.data?.error) {
        const apiError = error.response.data;
        showError(`ERRORS.API.${apiError?.error}`, {
          itemName: itemOrOverride(entityOverwrite),
        });
        console.error('Request Message: ', apiError?.message);
        console.error('Request Debug: ', apiError?.debug);
      }
    },
    success: showSuccess,
    successDeleted: (entityOverwrite?: string) =>
      showSuccess('SUCCESS.DELETED', {
        itemName: itemOrOverride(entityOverwrite),
      }),
    successCreated: (entityOverwrite?: string) =>
      showSuccess('SUCCESS.CREATED', {
        itemName: itemOrOverride(entityOverwrite),
      }),
    successSaved: (entityOverwrite?: string) =>
      showSuccess('SUCCESS.SAVED', {
        itemName: itemOrOverride(entityOverwrite),
      }),
  };
}

export function useScrollAnchor() {
  const contentElement = useRef<any>(null);

  const scrollToContent = () => {
    if (contentElement.current) {
      const headerOffset = window.matchMedia('(max-width: 1024px)').matches
        ? 85
        : 120;
      const elementPosition = (contentElement?.current as HTMLElement)?.getBoundingClientRect()
        .top;
      window.scrollBy({
        top: elementPosition - headerOffset,
        behavior: 'smooth',
      });
    }
  };

  return { contentElement, scrollToContent };
}

export function useStateWithCallback<T>(
  initialState: T,
): [T, (newValue: SetStateAction<T>, callback?: (arg: T) => void) => void] {
  const callbackRef = useRef<((arg: T) => void) | null>(null);

  const [value, setValue] = useState<T>(initialState);

  useEffect(() => {
    if (callbackRef.current) {
      callbackRef.current(value);

      callbackRef.current = null;
    }
  }, [value]);

  const setValueWithCallback = (
    newValue: SetStateAction<T>,
    callback?: (arg: T) => void,
  ) => {
    callbackRef.current = callback || null;

    return setValue(newValue);
  };

  return [value, setValueWithCallback];
}

export function useFilterChange<T extends object>(
  filters: T,
  callback: (filters: T) => void,
) {
  const previous = useRef<T>();

  useEffect(() => {
    // do not run on initial render when the previous filters are undefined
    if (previous.current && !isEqual(previous.current, filters)) {
      callback(filters);
    }
  }, [filters]);

  useEffect(() => {
    previous.current = filters;
  });
}
