import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { LayoutSplashScreen } from '../../../_metronic';
import Dashboard from './Dashboard/Dashboard';
import DashboardPage from './Dashboard/DashboardPage';

const BillingPage = lazy(() => import('../billing/BillingPage'));

export default function HomePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {/* Redirect from root URL to /dashboard. */}
        <Redirect exact from="/" to="/dashboard" />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/license" component={DashboardPage} />
        <Route path="/billing" component={BillingPage} />
        <Redirect to="/error/error-v1" />
      </Switch>
    </Suspense>
  );
}
