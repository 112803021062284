import DashboardIcon from './aside/menu-icons/DashboardIcon';
// @ts-ignore
import { ReactComponent as IpIcon } from '../../app/partials/layout/headerIcons/ip-icon.svg';
// @ts-ignore
import { User, userAccessRights } from '../../app/crud/users/models/User';
import { SectionName } from '../../app/crud/users/models/PlatformRole';

export const menuConfigForUser = (user?: User) => ({
  header: {
    self: {},
    items: [
      {
        title: 'Dashboards',
        root: true,
        alignment: 'left',
        page: 'dashboard',
        translate: 'MENU.DASHBOARD',
      },
    ],
  },
  aside: {
    self: {},
    items: [
      userAccessRights(user, SectionName.FUND_MASTER_FILE) && {
        translate: 'MENU.DASHBOARD',
        root: true,
        toggle: 'click',
        bullet: 'dot',
        SVGIcon: DashboardIcon,
        'icon-only': true,
        page: 'dashboard',
      },
      {
        translate: 'MENU.BILLING',
        root: false,
        toggle: 'click',
        bullet: 'dot',
        SVGIcon: IpIcon,
        'icon-only': true,
        page: 'billing/list',
      },
    ].filter(menuItem => !!menuItem),
  },
});

export default menuConfigForUser();
