import { toAbsoluteUrl } from '../utils/utils';

export const initLayoutConfig = {
  demo: 'demo1',
  // == Base Layout
  self: {
    layout: 'fluid',
    body: {
      class:
        'kt-subheader--fixed kt-subheader--solid kt-aside--enabled kt-aside--fixed kt-page--loading',
    },
    // body: { "background-image": toAbsoluteUrl("/media/misc/bg-1.jpg") },
    logo: {
      dark: toAbsoluteUrl('/media/logos/licensing/gapp-licensing.svg'),
      light: toAbsoluteUrl('/media/logos/licensing/gapp-licensing.svg'),
      brand: toAbsoluteUrl('/media/logos/licensing/gapp-licensing.svg'),
      green: toAbsoluteUrl('/media/logos/licensing/gapp-licensing.svg'),
    },
  },
  // == Portlet Plugin
  // portlet: {
  //   sticky: {
  //     offset: 50
  //   }
  // },
  // == Page Splash Screen loading
  loader: {
    enabled: true,
    type: 'spinner-logo',
    logo: toAbsoluteUrl('/media/logos/licensing/gapp-licensing.svg'),
    message: 'Please wait...',
  },
  // == Colors for javascript
  colors: {
    state: {
      brand: '#749e9e',
      dark: '#137c7f',
      light: '#ffffff',
      primary: '#75b9e6',
      success: '#9ACA3C',
      info: '#20A3D8',
      warning: '#ffc72c',
      danger: '#e61657',
    },
    base: {
      kim: ['#f8fafb', '#e1e1e1', '#bcc0cd'],
      label: ['#c5cbe3', '#a1a8c3', '#3d4465', '#3e4466'],
      shape: ['#f0f3ff', '#d9dffa', '#afb4d4', '#646c9a'],
    },
  },
  // page toolbar
  toolbar: {
    display: false,
  },
  header: {
    self: {
      width: 'fluid', // fixed|fluid
      skin: 'light',
      fixed: {
        desktop: true,
        mobile: true,
      },
    },
    menu: {
      self: {
        display: true,
        layout: 'default',
        'root-arrow': false,
        'icon-style': 'duotone',
      },
      desktop: {
        arrow: true,
        toggle: 'click',
        submenu: {
          skin: 'light',
          arrow: true,
        },
      },
      mobile: {
        submenu: {
          skin: 'dark',
          accordion: true,
        },
      },
    },
  },
  subheader: {
    display: false,
  },
  content: {
    width: 'fluid',
  },
  brand: {
    self: {
      skin: 'light',
    },
  },
  aside: {
    self: {
      skin: 'light',
      fixed: true,
      display: true,
      minimize: {
        toggle: true,
        default: true,
      },
    },
    menu: {
      dropdown: false,
      scroll: true,
      submenu: {
        accordion: true,
        dropdown: {
          arrow: true,
          'hover-timeout': 500,
        },
      },
    },
  },
  footer: {
    self: {
      width: 'fluid',
      fixed: false,
    },
  },
};

const LayoutConfig = JSON.parse(JSON.stringify(initLayoutConfig)); // deep object copy
export default LayoutConfig;
