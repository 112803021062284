import React, { useRef } from 'react';
import { Dropdown } from 'react-bootstrap';
import clsx from 'clsx';
import { useTranslate } from '../../../../_metronic/utils/customHooks';

const MAX_PAGE_LINKS = 5;

type ListPaginationProps = {
  gotoPage: (page: number) => void;
  canPreviousPage: boolean;
  previousPage: () => void;
  nextPage: () => void;
  canNextPage: boolean;
  pageSize: number;
  setPageSize: (pageSize: number) => void;
  totalItems: number;
  pageIndex: number;
  pageCount: number;
};

export default function ListPagination({
  gotoPage,
  canPreviousPage,
  previousPage,
  nextPage,
  canNextPage,
  pageSize,
  setPageSize,
  totalItems,
  pageIndex,
  pageCount,
}: ListPaginationProps) {
  const t = useTranslate();
  const id = useRef(Math.random());

  // Page Links are buttons that directly link to a page
  // they start at the closest multiple of MAX_PAGE_LINKS
  // same behaviour as https://preview.keenthemes.com/metronic/demo1/crud/ktdatatable/base/data-local.html
  const startingPageLink =
    Math.floor(pageIndex / MAX_PAGE_LINKS) * MAX_PAGE_LINKS;
  const pageLinks = Array.from(
    Array(Math.min(pageCount, MAX_PAGE_LINKS)),
    (x, index) => index + startingPageLink,
  );
  return (
    <div className="kt-list-pagination">
      <button
        className="kt-list-pagination-button"
        type="button"
        onClick={() => gotoPage(0)}
        disabled={!canPreviousPage}
      >
        <i className="la la-angle-double-left" />
      </button>
      <button
        className="kt-list-pagination-button"
        type="button"
        onClick={() => previousPage()}
        disabled={!canPreviousPage}
      >
        <i className="la la-angle-left" />
      </button>
      {pageLinks.map(page => (
        <button
          key={page}
          className={clsx({
            'kt-list-pagination-button': true,
            'kt-list-pagination-link': true,
            active: page === pageIndex,
          })}
          type="button"
          onClick={() => gotoPage(page)}
        >
          {page + 1}
        </button>
      ))}
      <button
        className="kt-list-pagination-button"
        type="button"
        onClick={() => nextPage()}
        disabled={!canNextPage}
      >
        <i className="la la-angle-right" />
      </button>
      <button
        className="kt-list-pagination-button"
        type="button"
        onClick={() => gotoPage(pageCount - 1)}
        disabled={!canNextPage}
      >
        <i className="la la-angle-double-right" />
      </button>
      <Dropdown
        className="kt-list-pagination-selector"
        onSelect={(eventKey: string) => setPageSize(Number(eventKey))}
      >
        <Dropdown.Toggle id={`${id.current}_pagination-selector`}>
          {pageSize}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {[10, 20, 30, 40, 50].map(p => (
            <Dropdown.Item as="button" key={p} eventKey={p.toString()}>
              {p}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      <span className="kt-list-pagination-records">
        {totalItems > 0
          ? t('PAGINATION.DISPLAYING_RECORDS', {
              total: totalItems,
              start: pageIndex * pageSize + 1,
              finish: Math.min(pageIndex * pageSize + pageSize, totalItems),
            })
          : t('PAGINATION.NO_RECORDS')}
      </span>
    </div>
  );
}
