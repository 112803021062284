import axios from 'axios';
import { User, serializeUser } from './models/User';
import APIResponse from '../base/models/APIResponse';

export const USER_URL = '/api/user';
export const LOGIN_URL = '/login';

export const AUTH_URL = '/api/auth';

export function getToken(credentials: any) {
  // Authorization head should be fulfilled in interceptor.
  return axios
    .post(`${LOGIN_URL}/getToken`, credentials)
    .then(({ data }) => data);
}

export function requestOTPToken(user: string) {
  return axios
    .post(`${AUTH_URL}/otp/generate`, { user })
    .then(({ data }) => data.data);
}

export function validateOTP(values: { user: string; token: string }) {
  return axios
    .post(`${AUTH_URL}/otp/validate`, values)
    .then(({ data }) => data.data);
}

export function verifyOTP(values: any) {
  // Authorization head should be fulfilled in interceptor.
  return axios.post(`${LOGIN_URL}/verifyOTP`, values).then(({ data }) => data);
}

export function createUser(values: Partial<User>) {
  return axios
    .post<APIResponse<User>>(USER_URL, serializeUser(values))
    .then(({ data }) => data.data);
}
