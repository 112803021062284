import React from 'react';

function DeleteIcon() {
  return (
    <svg
      width="24"
      height="27"
      viewBox="0 0 24 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 6.99999H16V5.19082C15.9765 4.49729 15.7002 3.84213 15.2316 3.36897C14.7629 2.89581 14.1402 2.64325 13.5 2.66665H10.5C9.85975 2.64325 9.23706 2.89581 8.76843 3.36897C8.2998 3.84213 8.02346 4.49729 8 5.19082V6.99999H3C2.73478 6.99999 2.48043 7.11412 2.29289 7.31729C2.10536 7.52045 2 7.796 2 8.08332C2 8.37064 2.10536 8.64619 2.29289 8.84935C2.48043 9.05252 2.73478 9.16665 3 9.16665H4V21.0833C4 21.9453 4.31607 22.7719 4.87868 23.3814C5.44129 23.9909 6.20435 24.3333 7 24.3333H17C17.7956 24.3333 18.5587 23.9909 19.1213 23.3814C19.6839 22.7719 20 21.9453 20 21.0833V9.16665H21C21.2652 9.16665 21.5196 9.05252 21.7071 8.84935C21.8946 8.64619 22 8.37064 22 8.08332C22 7.796 21.8946 7.52045 21.7071 7.31729C21.5196 7.11412 21.2652 6.99999 21 6.99999ZM10 5.19082C10 5.01749 10.21 4.83332 10.5 4.83332H13.5C13.79 4.83332 14 5.01749 14 5.19082V6.99999H10V5.19082Z"
        fill="#FC4C87"
      />
    </svg>
  );
}

export default React.memo(DeleteIcon);
