import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import LicenseEdit from './license-edit/LicenseEdit';

export default function DashboardPage() {
  return (
    <div>
      <Switch>
        <Route exact path="/license/add" component={LicenseEdit} />
        <Route path="/license/add/:id" component={LicenseEdit} />
        <Redirect to="/error/error-v1" />
      </Switch>
    </div>
  );
}
