import React from 'react';
import UserProfile from '../../../app/partials/layout/UserProfile';

export default function Topbar() {
  return (
    <div className="kt-header__topbar">
      {/*  <LanguageSelector iconType="" /> */}
        <div style={{display: 'flex'}}>
            <UserProfile />
        </div>
    </div>
  );
}
