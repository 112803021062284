import axios from 'axios';
import APIResponse from '../base/models/APIResponse';
import License, { serializeLicense } from './models/License';

export const LICENSE_URL = '/api/license';

export function getLicense(id: string): Promise<License | null> {
  return axios
    .get<APIResponse<License>>(`${LICENSE_URL}/${id}`)
    .then(({ data }) => data.data);
}

export function getListLicenses() {
  return axios
    .get<APIResponse<License[]>>(`${LICENSE_URL}/list`)
    .then(({ data }) => data.data);
}

export function updateLicense(values: Partial<License>) {
  return axios
    .put<APIResponse<License>>(
      `${LICENSE_URL}/${values.id}`,
      serializeLicense(values),
    )
    .then(({ data }) => data.data);
}

export function removeLicense(id: string) {
  return axios.delete<APIResponse<string>>(`${LICENSE_URL}/${id}`);
}

export function createLicense(values: Partial<License>) {
  return axios
    .post<APIResponse<License>>(LICENSE_URL, serializeLicense(values))
    .then(({ data }) => data.data);
}
