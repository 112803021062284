import React, { useRef } from 'react';
import './checkboxButton.scss';
import clsx from 'clsx';
import { randomId } from '../../../../../_metronic';

type CheckboxButtonProps = {
  className?: string;
  id?: string;
  hint?: string;
  name?: string;
  onChange: ((event: React.ChangeEvent<HTMLInputElement>) => void) | undefined;
  checked?: boolean;
};

export default function CheckboxButton({
  className,
  id,
  hint,
  onChange,
  checked,
  name,
}: CheckboxButtonProps) {
  const idValue = useRef(id || `a_${randomId()}`);

  return (
    <>
      <div className={`checkbox-button-holder ${className || ''}`}>
        <div className="checkbox-button">
          <label
            className={clsx('checkbox-label', {
              checked: checked,
            })}
            htmlFor={idValue.current}
          >
            <input
              id={idValue.current}
              type="checkbox"
              name={name}
              onChange={onChange}
              checked={checked}
            />
            <span className="checkmark">
              {checked && <i className="fa fa-check" />}
            </span>
          </label>
        </div>
      </div>
      <span className="col-11 hint-checkbox">{hint}</span>
    </>
  );
}
