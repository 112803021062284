import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useQuery } from '../../../_metronic/utils/customHooks';
import { actions } from '../../store/ducks/auth/auth.duck';

function Authenticate() {
  const query = useQuery();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.login());
  }, [query]);

  return null;
}

export default Authenticate;
