import React from 'react';
import { Cell } from 'react-table';
import Tooltip from '../../Tooltip';

type DateColumnProps<T extends object> = Cell<T, number | undefined | null> & {
  tooltip?: string;
};

export default function DateCell<T extends object>(props: DateColumnProps<T>) {
  const { value, tooltip } = props;
  const date = value ? new Date(value) : null;

  const Wrapper = ({ children }: any) =>
    tooltip ? <Tooltip tooltip={tooltip}>{children}</Tooltip> : <>{children}</>;

  return (
    <div>
      <Wrapper>
        {/* eslint-disable-next-line no-nested-ternary */}
        {date ? (
          date.getTime() === -1 ? (
            'Unlimited'
          ) : (
            <span>
              {new Intl.DateTimeFormat('en-GB').format(new Date(date))}
            </span>
          )
        ) : null}
      </Wrapper>
    </div>
  );
}
