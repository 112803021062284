import React from 'react';

function DashboardIcon() {
    return (
        <svg width="18" height="19" viewBox="0 0 18 19" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 0.5H3C2.20435 0.5 1.44129 0.816071 0.87868 1.37868C0.316071 1.94129 0 2.70435 0 3.5V15.5C0 16.2956 0.316071 17.0587 0.87868 17.6213C1.44129 18.1839 2.20435 18.5 3 18.5H15C15.7956 18.5 16.5587 18.1839 17.1213 17.6213C17.6839 17.0587 18 16.2956 18 15.5V3.5C18 2.70435 17.6839 1.94129 17.1213 1.37868C16.5587 0.816071 15.7956 0.5 15 0.5ZM3 2.5H15C15.2652 2.5 15.5196 2.60536 15.7071 2.79289C15.8946 2.98043 16 3.23478 16 3.5V5.5H2V3.5C2 3.23478 2.10536 2.98043 2.29289 2.79289C2.48043 2.60536 2.73478 2.5 3 2.5V2.5ZM2 15.5V7.5H8V16.5H3C2.73478 16.5 2.48043 16.3946 2.29289 16.2071C2.10536 16.0196 2 15.7652 2 15.5ZM15 16.5H10V7.5H16V15.5C16 15.7652 15.8946 16.0196 15.7071 16.2071C15.5196 16.3946 15.2652 16.5 15 16.5Z"   fill="#B8BAC3"/>
        </svg>
    );
}

export default React.memo(DashboardIcon);
