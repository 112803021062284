import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import * as builder from '../../ducks/builder';
import Menu from './Menu';
import KTOffcanvas from '../../_assets/js/offcanvas';
import clsx from "clsx";

export class AsideLeft extends React.Component {
  asideOffCanvasRef = React.createRef();

  constructor(props) {
      super(props);
      this.state = {
          expanded: false
      }
      this.state = {
          widthScreen: window.innerWidth
      }
      this.toggleExpanded = this.toggleExpanded.bind(this);
      this.collapse = this.collapse.bind(this);
      this.getWidth = this.getWidth.bind(this);

  }
  toggleExpanded() {
      this.setState({ expanded: true });
  }
  collapse() {
      this.setState({ expanded: false });
  }
  getWidth() {
      this.setState({ widthScreen: window.innerWidth})
  }
  componentDidMount() {
    // eslint-disable-next-line
    const ktoffConvas = new KTOffcanvas(
      this.asideOffCanvasRef.current,
      this.props.menuCanvasOptions,
    );
      window.addEventListener('resize', this.getWidth);
  }
  componentWillUnmount() {
      window.removeEventListener('resize', this.getWidth);
  }

    render() {
        return (
          <>
            <button className="kt-aside-close" id="kt_aside_close_btn">
              <i className="la la-close" />
            </button>
            <div
              id="kt_aside"
              ref={this.asideOffCanvasRef}
              className={clsx(`kt-aside ${this.props.asideClassesFromConfig} kt-grid__item kt-grid kt-grid--desktop kt-grid--hor-desktop`,
                  {
                      'expanded': this.state.expanded
                  })}
            >
                <div
                  id="kt_aside_menu_wrapper"
                  className="kt-aside-menu-wrapper kt-grid__item kt-grid__item--fluid"
                >
                    <Menu
                        toggleExpanded={this.toggleExpanded}
                        collapse={this.collapse}
                        expanded={this.state.expanded}
                        htmlClassService={this.props.htmlClassService}
                        widthScreen={this.state.widthScreen}
                    />
                </div>
            </div>
          </>
        );
  }
}

const mapStateToProps = store => ({
  disableAsideSelfDisplay:
    builder.selectors.getConfig(store, 'aside.self.display') === false,
  asideClassesFromConfig: builder.selectors.getClasses(store, {
    path: 'aside',
    toString: true,
  }),
  menuCanvasOptions: {
    baseClass: 'kt-aside',
    overlay: true,
    closeBy: 'kt_aside_close_btn',
    toggleBy: {
      target: 'kt_aside_mobile_toggler',
      state: 'kt-header-mobile__toolbar-toggler--active',
    },
  },
});

export default connect(mapStateToProps)(AsideLeft);
