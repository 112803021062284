import React from 'react';
import { CircularProgress } from '@material-ui/core';
import './loadingIndicator.scss';

type LoadingIndicatorProps = { size?: number | string };

export default function LoadingIndicator({ size = 40 }: LoadingIndicatorProps) {
  return (
    <div className="kt-spinner-container">
      <CircularProgress size={size} />
    </div>
  );
}
