/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as builder from '../../ducks/builder';

class Brand extends React.Component {
  render() {
    return (
      <div
        className={`kt-aside__brand kt-grid__item ${this.props.brandClasses}`}
        id="kt_aside_brand"
      >
        <div className="kt-aside__brand-logo">
          <Link to="">
            <img width="120" alt="logo" src={this.props.headerLogo} />
          </Link>
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  brandClasses: builder.selectors.getClasses(store, {
    path: 'brand',
    toString: true,
  }),
  headerLogo: builder.selectors.getLogo(store),
  headerStickyLogo: builder.selectors.getStickyLogo(store),
});

export default connect(mapStateToProps)(Brand);
