import React from 'react';
import { FormattedMessage } from 'react-intl';
import {useTranslate} from "../../utils/customHooks";
import clsx from "clsx";

export default function MenuItemText({ item, parentItem, expanded }) {

  const useSVGIcon = item && item.SVGIcon;
  const useNormalIcon = item && item.icon && !useSVGIcon;
  const t = useTranslate();
  return (
    <>
      {useNormalIcon && <i className={`kt-menu__link-icon ${item.icon}`} />}
      {useSVGIcon && (
          <span className="kt-menu__link-icon">
              <item.SVGIcon />
          </span>
      )}

      {parentItem && parentItem.bullet === 'dot' && (
        <i className="kt-menu__link-bullet kt-menu__link-bullet--dot">
          <span />
        </i>
      )}

      {parentItem && parentItem.bullet === 'line' && (
        <i className="kt-menu__link-bullet kt-menu__link-bullet--line">
          <span />
        </i>
      )}

      <span className={clsx('kt-menu__link-text',
          {
              'collapsed-side_menu': expanded
          })
      } style={{ whiteSpace: 'nowrap' }}>
        {!item.translate ? (
          item.title
        ) : (
          <FormattedMessage id={item.translate} defaultMessage={item.title} />
        )}
      </span>

      {item.badge && (
        <span className="kt-menu__link-badge">
          <span className={`kt-badge ${item.badge.type}`}>
            {item.badge.value}
          </span>
        </span>
      )}

      {item.submenu && <i className="kt-menu__ver-arrow la la-angle-right" />}
    </>
  );
}
