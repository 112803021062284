import React from 'react';
import { Link } from 'react-router-dom';
import './backToList.scss';

type BackToListProps = {
  listUrl: string;
  title: string;
};

export default function BackTo({ listUrl, title }: BackToListProps) {
  return (
    <div className="back-to-list-container">
      <Link to={listUrl} className="back-to-list-button">
        <span className="back-to-list-holder">
          <i className="fas fa-arrow-left" />
        </span>
        <span className="back-to-list-text">{title}</span>
      </Link>
    </div>
  );
}
