import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  useToast,
  useTranslate,
} from '../../../../../_metronic/utils/customHooks';
import {
  createLicense,
  getLicense,
  updateLicense,
} from '../../../../crud/license/license.crud';
import License from '../../../../crud/license/models/License';
import LicenseForm, { LicenseDetailsHandle } from './LicenseForm';
import { Portlet, PortletBody } from '../../../../partials/content/Portlet';

export default function LicenseEdit() {
  const t = useTranslate();
  const history = useHistory();
  const toast = useToast();
  const LicenseEditRef = useRef<LicenseDetailsHandle>(null);
  const { id } = useParams<{ id: string }>();
  const [licenseObject, setLicense] = useState<Partial<License> | undefined>();

  useEffect(() => {
    if (id) {
      const fetchData = async () => {
        const licenseFetch = await getLicense(id);
        if (licenseFetch) {
          setLicense(licenseFetch);
        }
      };
      fetchData().catch(err => {
        toast.errorFromAPI(err);
      });
    }
  }, [id]);

  const onSaveAdd = useCallback(async () => {
    const licenseEdit = await LicenseEditRef?.current?.getData();
    const licenseToCreate = { ...licenseEdit };
    const isValid = await LicenseEditRef?.current?.isValid();
    if (!isValid) {
      toast.error('ERRORS.VALIDATION.FILL_ALL');
      return;
    }
    try {
      const newLicense = await createLicense({
        ...licenseToCreate,
      });
      if (newLicense) {
        await getLicense(newLicense.id);
        history.push(`/dashboard`);
      }
    } catch (err) {
      toast.errorFromAPI(err);
    }
  }, []);

  const onSaveEdit = useCallback(async (licenseId: string | undefined) => {
    const licenseEdit = await LicenseEditRef?.current?.getData();
    const ts = licenseEdit?.expiry;
    const epoch = new Date(ts!).getTime();
    const values = {
      ...licenseEdit,
      id: licenseId,
      expiry: epoch,
    };
    const isValid = await LicenseEditRef?.current?.isValid();
    if (!isValid) {
      toast.error('ERRORS.VALIDATION.FILL_ALL');
      return;
    }
    try {
      const editedLicense = await updateLicense({
        ...values,
      });
      if (editedLicense) {
        toast.success('license.add.success');
        history.push(`/dashboard`);
      }
    } catch (err) {
      toast.errorFromAPI(err);
    }
  }, []);

  return (
    <Portlet>
      <PortletBody>
        <div className="row">
          <div className="col-lg-12">
            <LicenseForm licenseObject={licenseObject} ref={LicenseEditRef} />
            <div className="form-footer">
              {licenseObject ? (
                <button
                  className="btn btn-success btn-pill"
                  onClick={() => onSaveEdit(id)}
                  type="button"
                >
                  {t('BASIC.SAVE.LICENSE')}
                </button>
              ) : (
                <button
                  className="btn btn-success btn-pill"
                  onClick={onSaveAdd}
                  type="button"
                >
                  {t('BASIC.ADD.LICENSE')}
                </button>
              )}
            </div>
          </div>
        </div>
      </PortletBody>
    </Portlet>
  );
}
