import React from 'react';
import './dashboard.scss';
import { Link } from 'react-router-dom';
import { Portlet, PortletBody } from '../../../partials/content/Portlet';
import { useTranslate } from '../../../../_metronic/utils/customHooks';
import LicenseList from './license-list/LicenseList';

export default function Dashboard() {
  const t = useTranslate();

  return (
    <div className="row">
      <div className="col-lg-12">
        <Portlet fluidHeight>
          <PortletBody>
            <div className="header">
              <h5 className="kt-portlet__head-title">{t('dashboard.title')}</h5>
              <Link
                data-testid="add-license"
                to="/license/add"
                className="btn btn-brand"
              >
                {t('dashboard.addLicense')}
              </Link>
            </div>
          </PortletBody>
          <LicenseList />
        </Portlet>
      </div>
    </div>
  );
}
