import React from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslate } from '../../../../_metronic/utils/customHooks';
import './confirmationModal.scss';
import DraggableModal from '../DraggableModal';

type ConfirmationModalProps = {
  itemName: string;
  titleKey?: string;
  messageKey?: string;
  onClose: (confirmed: boolean) => void;
  show: boolean;
};

export default function ConfirmationModal({
  onClose,
  titleKey,
  itemName,
  messageKey,
  show,
}: ConfirmationModalProps) {
  const t = useTranslate();
  const finalMessage = messageKey || 'CONFIRM_MODAL.MESSAGE';
  return (
    <Modal
      show={show}
      dialogAs={DraggableModal}
      onHide={() => onClose(false)}
      className="confirmation-modal"
    >
      <Modal.Body>
        <h3 className="confirmation-modal-title modal-title-draggable">
          {titleKey ? t(titleKey) : t('CONFIRM_MODAL.TITLE')}
        </h3>
        <p className="confirmation-modal-message">
          {t(finalMessage, { itemName })}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-pill btn-outline-secondary"
          onClick={() => onClose(false)}
        >
          {t('BASIC.CANCEL')}
        </button>
        <button
          type="button"
          className="btn btn-pill btn-danger"
          onClick={() => onClose(true)}
        >
          {t('BASIC.CONFIRM')}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
