import React from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import HeaderDropdownToggle from '../content/CustomDropdowns/HeaderDropdownToggle';
// @ts-ignore
import { ReactComponent as UserIcon } from './headerIcons/user.svg';
import { useTranslate } from '../../../_metronic/utils/customHooks';
// @ts-ignore
import UserAvatar from './headerIcons/user-avatar.png';

export default function UserProfile() {
  const t = useTranslate();

  return (
    <Dropdown
      className="kt-header__topbar-item kt-header__topbar-item--user"
      drop="down"
      alignRight
    >
      <Dropdown.Toggle
        as={HeaderDropdownToggle}
        id="dropdown-toggle-user-profile"
      >
        <div className="kt-header__topbar-user">
          <UserIcon />
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
        <div className="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x">
          <div className="kt-user-card__avatar">
            <img className="avatar" src={UserAvatar} alt="user-avatar" />
          </div>
          <div className="kt-user-card__text">
            <div className="kt-user-card__name">
              {t('user.mobilu_administrator')}
            </div>
          </div>
        </div>
        <div className="kt-notification">
          <div className="kt-notification__custom logout">
            <Link to="/logout" className="btn btn-sm btn-bold">
              {t('AUTH.SIGN_OUT')}
            </Link>
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}
