import React from 'react';
import { connect } from 'react-redux';
import objectPath from 'object-path';
import * as builder from '../../ducks/builder';

type FooterProps = { footerClasses: string; footerContainerClasses: string };
function Footer({ footerClasses, footerContainerClasses }: FooterProps) {
  const today = new Date().getFullYear();
  const buildNumber = process.env.REACT_APP_BUILD_NUMBER;

  return (
    <div
      className={`kt-footer ${footerClasses} kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop`}
      id="kt_footer"
    >
      <div className={`kt-container ${footerContainerClasses}`}>
        <div className="kt-footer__copyright">
          {today.toString()}&nbsp;&copy;&nbsp;
          <a
            href="https://www.mobilu.eu/"
            target="_blank"
            rel="noopener noreferrer"
            className="kt-link"
          >
            UNICORN
          </a>
        </div>
        <div
          className="kt-footer__menu"
          style={{
            fontSize: '1rem',
            fontWeight: 400,
            color: '#74788d',
          }}
        >
          {buildNumber}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (store: any) => ({
  fluid:
    objectPath.get(store.builder.layoutConfig, 'footer.self.width') === 'fluid',
  footerClasses: builder.selectors.getClasses(store, {
    path: 'footer',
    toString: true,
  }),
  footerContainerClasses: builder.selectors.getClasses(store, {
    path: 'footer_container',
    toString: true,
  }),
});

export default connect(mapStateToProps)(Footer);
