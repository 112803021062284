import React from 'react';
import Draggable from 'react-draggable';
import { ModalDialog, ModalDialogProps } from 'react-bootstrap';

const DraggableModal = (props: ModalDialogProps) => (
  <Draggable handle=".modal-title-draggable">
    <ModalDialog {...props} />
  </Draggable>
);

export default DraggableModal;
