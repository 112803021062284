import React, { ForwardedRef, forwardRef, useImperativeHandle } from 'react';
import '../dashboard.scss';
import { FormikErrors, FormikTouched, FormikValues, useFormik } from 'formik';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import License, { LicenseEdit } from '../../../../crud/license/models/License';
import { useTranslate } from '../../../../../_metronic/utils/customHooks';
import LicenseEditionHandle from './LicenseEditionHandle';
import DatePicker from '../../../../partials/content/Forms/DatePicker/DatePicker';
import CheckboxButton from '../../../../partials/content/Forms/CheckBoxButton/CheckboxButton';
import BackTo from '../../../../partials/content/BackToList/BackTo';

export type LicenseDetailsHandle = LicenseEditionHandle<Partial<LicenseEdit>>;

type LicenseFormProps = {
  licenseObject?: Partial<License>;
};

const LicenseForm = forwardRef<LicenseDetailsHandle, LicenseFormProps>(
  (
    {
      // eslint-disable-next-line react/prop-types
      licenseObject,
    },
    ref: ForwardedRef<LicenseDetailsHandle>,
  ) => {
    const t = useTranslate();
    const requiredFields: (keyof License)[] = ['organizationName'];
    const requiredIndicator = (field: keyof License) =>
      requiredFields.includes(field) ? (
        <span className="required">*</span>
      ) : null;

    const validate = (values: FormikValues) => {
      const errors = {};
      requiredFields.forEach(field => {
        if (!values[field]) {
          errors[field] = t('ERRORS.VALIDATION.REQUIRED');
        }
      });
      return errors;
    };
    const errorMessage = (
      field: string,
      errors: FormikErrors<License>,
      touched: FormikTouched<License>,
    ) => (
      <div className="invalid-feedback">
        {errors[field] && touched[field] ? errors[field] : null}
      </div>
    );
    // @ts-ignore
    const expiryEpochTime = new Date(licenseObject?.expiry).getTime();
    const initialValues = {
      organizationName: licenseObject?.organizationName || '',
      expiry: licenseObject?.expiry || null,
      licenseKey: licenseObject?.key || '',
      isUnlimited: expiryEpochTime === -1,
      isPlatform: licenseObject?.isPlatform || false,
      meeting: licenseObject?.meeting ?? -1,
      gapp: licenseObject?.gapp ?? -1,
      notes: licenseObject?.notes ?? -1,
      isBlocked: licenseObject?.isBlocked || false,
    };

    const {
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      validateForm,
      setFieldValue,
      setValues,
    } = useFormik({
      initialValues,
      validate,
      onSubmit: () => undefined,
      enableReinitialize: true,
    });

    useImperativeHandle(ref, () => ({
      isValid: () =>
        validateForm().then(newErrors => {
          if (Object.entries(newErrors).length !== 0) {
            handleSubmit();
            return false;
          }
          return true;
        }),
      getData: () => ({
        ...values,
      }),
    }));

    return (
      <>
        <div className="back-to-list-container">
          <BackTo listUrl="/dashboard" title={t('BASIC.BACK_TO_LIST')} />
        </div>
        <h2 className="kt-portlet__head-title license-title">
          {licenseObject ? t('license.edit.title') : t('license.add.title')}
        </h2>
        <form
          data-testid="add-license-form"
          className="form-container license-form"
          onSubmit={handleSubmit}
          autoComplete="off"
        >
          <input type="hidden" value="test" />
          <div className="form-group row">
            <div className="col-lg-10 license-edit">
              <label className="col-3" htmlFor="organizationName">
                {t('license.add.organizationName')}
                {requiredIndicator('organizationName')}
              </label>
              <input
                id="organizationName"
                type="text"
                className={clsx('form-control control-height', {
                  'is-invalid':
                    errors.organizationName && touched.organizationName,
                })}
                name="organizationName"
                value={values.organizationName}
                onChange={handleChange}
                placeholder={t('license.add.organizationName.placeholder')}
                onBlur={handleBlur}
                autoComplete="off"
              />
              {errorMessage('organizationName', errors, touched)}
            </div>
          </div>
          <div className="form-group row">
            <div className="col-lg-10 license-edit">
              <label className="col-3" htmlFor="key">
                {t('license.add.licenseKey')}
              </label>
              <input
                id="key"
                type="text"
                className="form-control control-height"
                name="key"
                value={values.licenseKey}
                onChange={handleChange}
                placeholder={t('license.add.licenseKey.placeholder')}
                onBlur={handleBlur}
                autoComplete="off"
                disabled
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="col-lg-10 license-edit">
              <label className="col-3" htmlFor="expiry">
                {t('license.add.expiry')}
                {requiredIndicator('expiry')}
              </label>
              <DatePicker
                className="date-input"
                id="expiry"
                hasError={!!(errors.expiry && touched.expiry)}
                placeholder={t('license.add.expiry.placeholder')}
                value={values.expiry}
                onChange={v => setFieldValue('expiry', v)}
                disabled={!!values.isUnlimited}
              />
              {errorMessage('expiry', errors, touched)}
            </div>
          </div>
          <div className="form-group row">
            <div className="col-lg-10 license-edit">
              <label className="col-3" htmlFor="isUnlimited">
                {t('license.add.unlimited')}
              </label>
              <CheckboxButton
                checked={values.isUnlimited}
                onChange={e => {
                  if (e.target.checked) {
                    setValues({ ...values, expiry: -1 });
                  } else {
                    setValues({
                      ...values,
                      expiry: undefined,
                    });
                  }
                  return handleChange(e);
                }}
                id="isUnlimited"
                name="isUnlimited"
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="col-lg-10 license-edit">
              <label className="col-3" htmlFor="isPlatform">
                {t('license.add.isPlatform')}
              </label>
              <CheckboxButton
                checked={values.isPlatform}
                onChange={handleChange}
                id="isPlatform"
                name="isPlatform"
                className="license-edit"
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="col-lg-10 license-edit">
              <label className="col-3" htmlFor="meeting">
                {t('license.add.meetings')}
              </label>
              <input
                className="form-control control-height"
                id="meeting"
                type="number"
                min={-1}
                name="meeting"
                value={values.meeting}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="col-lg-10 license-edit">
              <label className="col-3" htmlFor="gapp">
                {t('license.add.gapp')}
              </label>
              <input
                className="form-control control-height"
                id="gapp"
                type="number"
                min={-1}
                name="gapp"
                value={values.gapp}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="col-lg-10 license-edit">
              <label className="col-3" htmlFor="notes">
                {t('license.add.notes')}
              </label>
              <input
                className="form-control control-height"
                id="notes"
                type="number"
                min={-1}
                name="notes"
                value={values.notes}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="col-lg-10 license-edit">
              <label className="col-3" htmlFor="lastUsed">
                {t('license.add.lastUsed')}
                {requiredIndicator('lastUsed')}
              </label>
              <DatePicker
                className="date-input"
                id="lastUsed"
                value={values.lastUsed}
                disabled
                onChange={v => setFieldValue('lastUsed', v)}
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="col-lg-10 license-edit">
              <label className="col-3" htmlFor="lastIP">
                {t('license.add.lastIPRequested')}
              </label>
              <input
                id="lastIP"
                type="text"
                className="form-control control-height"
                name="lastIP"
                value={values.lastIP}
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete="off"
                disabled
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="col-lg-10 license-edit">
              <label className="col-3" htmlFor="isBlocked">
                {t('license.add.isBlocked')}
              </label>
              <CheckboxButton
                checked={values.isBlocked}
                onChange={handleChange}
                id="isBlocked"
                name="isBlocked"
              />
            </div>
          </div>
        </form>
      </>
    );
  },
);

export default LicenseForm;

// @ts-ignore
LicenseForm.propTypes = {
  licenseObject: PropTypes.shape({
    organizationName: PropTypes.string.isRequired,
    expiry: PropTypes.number.isRequired,
    key: PropTypes.string.isRequired,
    isPlatform: PropTypes.bool.isRequired,
    isBlocked: PropTypes.bool.isRequired,
    meeting: PropTypes.number.isRequired,
    notes: PropTypes.number.isRequired,
    gapp: PropTypes.number.isRequired,
  }),
};
