import React from 'react';
import { Link, Switch, Route, Redirect } from 'react-router-dom';
import '../../../_metronic/_assets/sass/pages/login/login-1.scss';
import Login from './Login';
import Authenticate from './Authenticate';
import ContactAdmin from './ContactAdmin';
import OTPAuthentication from './OTPAuthentication';
import ResetPassword from './ResetPassword';
import Brand from '../../../_metronic/layout/brand/Brand';

export default function AuthPage() {
  return (
    <>
      <div className="kt-grid kt-grid--ver kt-grid--root">
        <div
          id="kt_login"
          className="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1"
        >
          <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
            <div className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper">
              <Switch>
                <Route
                  path={['/auth/login', '/auth/error']}
                  component={Login}
                />
                <Route
                  path={['/auth/otp', '/auth/error']}
                  component={OTPAuthentication}
                />
                <Route
                  path={['/auth/reset', '/auth/error']}
                  component={ResetPassword}
                />
                <Route path="/auth/authenticate" component={Authenticate} />
                <Route path="/auth/contactAdmin" component={ContactAdmin} />
                <Redirect from="/auth" exact to="/auth/login" />
                <Redirect to="/auth/login" />
              </Switch>
            </div>
            <div className="kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside">
              <div className="kt-grid__item">
                <Link to="/" className="kt-login__logo">
                  <Brand />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
