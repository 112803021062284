import qs from 'qs';
import { AxiosInstance } from 'axios';
import { Store } from 'redux';
import { actions } from '../../app/store/ducks/auth/auth.actions';

export function removeCSSClass(ele: HTMLElement, cls: string) {
  const reg = new RegExp(`(\\s|^)${cls}(\\s|$)`);
  // eslint-disable-next-line no-param-reassign
  ele.className = ele.className.replace(reg, ' ');
}

export function addCSSClass(ele: HTMLElement, cls: string) {
  ele.classList.add(cls);
}

export const toAbsoluteUrl = (pathname: string) =>
  process.env.PUBLIC_URL + pathname;

export function setupAxios(axios: AxiosInstance, store: Store) {
  axios.interceptors.response.use(
    response => response,
    error => {
      if (error.response.status === 401 || error.response.status === 403) {
        store.dispatch(actions.logout());
      }
      return Promise.reject(error);
    },
  );

  // eslint-disable-next-line no-param-reassign
  axios.defaults.paramsSerializer = params =>
    qs.stringify(params, { arrayFormat: 'repeat' });
}

/*  removeStorage: removes a key from localStorage and its sibling expiracy key
    params:
        key <string>     : localStorage key to remove
    returns:
        <boolean> : telling if operation succeeded
 */
export function removeStorage(key: string) {
  try {
    localStorage.setItem(key, '');
    localStorage.setItem(`${key}_expiresIn`, '');
  } catch (e) {
    console.log(
      `removeStorage: Error removing key [${key}] from localStorage: ${JSON.stringify(
        e,
      )}`,
    );
    return false;
  }
  return true;
}

/*  getStorage: retrieves a key from localStorage previously set with setStorage().
    params:
        key <string> : localStorage key
    returns:
        <string> : value of localStorage key
        null : in case of expired key or failure
 */
export function getStorage(key: string) {
  const now = Date.now(); // epoch time, lets deal only with integer
  // set expiration for storage
  let expiresIn: string | number | null = localStorage.getItem(
    `${key}_expiresIn`,
  );
  if (expiresIn === undefined || expiresIn === null) {
    expiresIn = 0;
  }

  expiresIn = Math.abs(Number(expiresIn));
  if (expiresIn < now) {
    // Expired
    removeStorage(key);
    return null;
  }
  try {
    return localStorage.getItem(key);
  } catch (e) {
    console.log(
      `getStorage: Error reading key [${key}] from localStorage: ${JSON.stringify(
        e,
      )}`,
    );
    return null;
  }
}
/*  setStorage: writes a key into localStorage setting a expire time
    params:
        key <string>     : localStorage key
        value <string>   : localStorage value
        expires <number> : number of seconds from now to expire the key
    returns:
        <boolean> : telling if operation succeeded
 */
export function setStorage(key: string, value: string, expires: number) {
  if (expires === undefined || expires === null) {
    // eslint-disable-next-line no-param-reassign
    expires = 60 * 60 * 1000; // default: seconds for 1 hour
  }

  const now = Date.now(); // millisecs since epoch time, lets deal only with integer
  const schedule = now + expires;
  try {
    localStorage.setItem(key, value);
    localStorage.setItem(`${key}_expiresIn`, String(schedule));
  } catch (e) {
    console.log(
      `setStorage: Error setting key [${key}] in localStorage: ${JSON.stringify(
        e,
      )}`,
    );
    return false;
  }
  return true;
}

export function debounce(func: (args: any) => any, delay: number) {
  let inDebounce: NodeJS.Timeout;
  return function debounced(...args: any) {
    // @ts-ignore
    const context = this;
    clearTimeout(inDebounce);
    inDebounce = setTimeout(() => {
      func.apply(context, args);
    }, delay);
  };
}

export function sortStrings(s1?: string, s2?: string): number {
  if (!s1 && !s2) {
    return 0;
  }
  if (!s1) {
    return 1;
  }
  if (!s2) {
    return -1;
  }
  return s1.localeCompare(s2);
}

export function randomId(): number {
  const max = Number.MAX_SAFE_INTEGER - 1;
  const min = Number.MAX_SAFE_INTEGER / 2;
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export function removeDuplicates<T>(items: T[], field?: keyof T) {
  const seen: { [key: string]: boolean } = {};
  return (items || []).filter(item => {
    const k = field ? item[field] : item;
    if ({}.hasOwnProperty.call(seen, k)) {
      return false;
    }
    seen[(k as unknown) as string] = true;
    return true;
  });
}
