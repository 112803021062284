import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import OtpInput from 'src/app/partials/content/OTP/OtpInput';
import {
  useQuery,
  useToast,
  useTranslate,
} from '../../../_metronic/utils/customHooks';
import '../../../_metronic/_assets/sass/pages/login/login-1.scss';
import { validateOTP, verifyOTP } from '../../crud/users/user.crud';

export default function OTPAuthentication() {
  const t = useTranslate();
  const toast = useToast();
  const query = useQuery();
  const [otp, setOtp] = useState('');
  const onChange = (value: string) => setOtp(value);

  const handleValidateOTP = useCallback(async (userValue, otpValue) => {
    try {
      const otpAuthUrlResult = await validateOTP({
        user: userValue,
        token: otpValue,
      });
      if (otpAuthUrlResult.otpValid) {
        const result = await verifyOTP({
          user: userValue,
          token: otpValue,
        });
        if (result.token) {
          window.location.href = '/auth/authenticate';
        }
      }
    } catch (err) {
      toast.error('login.requestOTP.error');
    }
  }, []);
  return (
    <>
      <div className="kt-login__head" />
      <div className="kt-login__body">
        <div className="kt-login__form">
          <div className="kt-login__title">
            <h3>{t('login.welcome')}</h3>
            <span className="kt-login__subtitle">
              {t('login.subtitle.otp')}
            </span>
          </div>
          <div className="kt-login__input">
            <div className="otp-group">
              <OtpInput value={otp} valueLength={6} onChange={onChange} />
            </div>
            <div className="otp-actions">
              <Link to="/auth/login">{t('login.otp.cancel')}</Link>
              <button
                type="button"
                className="btn btn-primary col-4 btn-elevate kt-login__btn-primary"
                onClick={() => handleValidateOTP(query.get('user'), otp)}
              >
                {t('AUTH.CONFIRM.BUTTON')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
