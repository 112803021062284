export const actionTypes = {
  Login: '[Login] Action',
  Logout: '[Logout] Action',
  UserRequested: '[Request User] Action',
  UserLoaded: '[Load User] Auth API',
};

export const actions = {
  login: () => ({ type: actionTypes.Login }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: () => ({ type: actionTypes.UserRequested }),
  fulfillUser: user => ({ type: actionTypes.UserLoaded, payload: { user } }),
};
