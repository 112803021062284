import React, { ReactNode, useRef } from 'react';
import { OverlayTrigger, Tooltip as BootstrapTooltip } from 'react-bootstrap';

type TooltipProps = {
  id?: string;
  tooltip: string;
  children: ReactNode;
};

export default function Tooltip({ id, tooltip, children }: TooltipProps) {
  const idRef = useRef(id || `tooltip_${Math.random()}`);

  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <BootstrapTooltip id={idRef.current}>{tooltip}</BootstrapTooltip>
      }
    >
      {children}
    </OverlayTrigger>
  );
}
