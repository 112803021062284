import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LayoutSplashScreen } from '../../../_metronic';
import { actions } from '../../store/ducks/auth/auth.duck';

export default function Logout() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.logout());
  }, []);

  const isLoggedIn = useSelector<{ auth: { isLoggedIn: boolean } }, boolean>(
    ({ auth }) => auth.isLoggedIn,
  );

  useEffect(() => {
    if (!isLoggedIn) {
      window.location.href = '/login/logout';
    }
  }, [isLoggedIn]);

  return <LayoutSplashScreen />;
}
