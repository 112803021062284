import React, { useCallback, useEffect, useRef, useState } from 'react';
import '../dashboard.scss';
import { Cell, Column } from 'react-table';
import { Link } from 'react-router-dom';
import {
  useToast,
  useTranslate,
} from '../../../../../_metronic/utils/customHooks';
import License from '../../../../crud/license/models/License';
import List, { ListHandle } from '../../../../partials/content/List/List';
import EditIcon from '../icons/EditIcon';
import DeleteIcon from '../icons/DeleteIcon';
import ConfirmationModal from '../../../../partials/content/ConfirmationModal/ConfirmationModal';
import {
  getListLicenses,
  removeLicense,
} from '../../../../crud/license/license.crud';
import DateCell from '../../../../partials/content/List/Cells/DateCell';

type DeleteModalInfo = {
  deleteId: string;
  showModal: boolean;
};

export default function LicenseList() {
  const tableRef = useRef<ListHandle>(null);
  const [refreshKey, setRefreshKey] = useState(0);
  const t = useTranslate();
  const toast = useToast();

  // get licenses
  const [licensesList, setLicensesList] = useState<License[]>();
  useEffect(() => {
    getListLicenses()
      .catch(err => {
        toast.errorFromAPI(err, 'license');
        return [];
      })
      .then(newList => {
        console.log(newList);
        setLicensesList(newList);
      });
  }, [refreshKey]);

  const [{ deleteId, showModal }, setModalInfo] = useState<DeleteModalInfo>({
    deleteId: '',
    showModal: false,
  });
  const handleDelete = useCallback(
    (id: string) => setModalInfo({ deleteId: id, showModal: true }),
    [],
  );

  const handleModalClose = useCallback(
    isConfirmed => {
      if (isConfirmed) {
        removeLicense(deleteId!)
          .catch(err => {
            toast.errorFromAPI(err);
            return null;
          })
          .then(response => {
            if (response) {
              toast.successDeleted();
              setRefreshKey(oldKey => oldKey + 1);
              tableRef?.current?.forceRefresh();
            }
          });
      }
      setModalInfo({ deleteId: '', showModal: false });
    },
    [deleteId],
  );

  const columns: Column<License>[] = [
    {
      Header: t('dashboard.table.active'),
      accessor: 'isBlocked',
      id: 'isBlocked',
      Cell: ({ value }: Cell<License, boolean>) => (
        <div>{value ? '❌' : '✅'}</div>
      ),
    },
    {
      Header: t('dashboard.table.clientName'),
      accessor: 'organizationName',
      id: 'organizationName',
    },
    {
      Header: t('dashboard.table.expiry'),
      accessor: 'expiry',
      id: 'expiry',
      Cell: DateCell,
    },
    {
      Header: t('dashboard.table.gappLicenses'),
      accessor: 'gapp',
      id: 'gapp',
      Cell: ({ value }: Cell<License, number>) => (
        <div>{value === -1 ? 'Unlimited' : value}</div>
      ),
    },
    {
      Header: t('dashboard.table.meetingLicenses'),
      accessor: 'meeting',
      id: 'meeting',
      Cell: ({ value }: Cell<License, number>) => (
        <div>{value === -1 ? 'Unlimited' : value}</div>
      ),
    },
    {
      Header: t('dashboard.table.lastUsed'),
      accessor: 'lastUsed',
      id: 'lastUsed',
      Cell: DateCell,
    },
    {
      Header: t('BASIC.ACTIONS'),
      id: 'actions',
      // @ts-ignore
      disableSortBy: true,
      // @ts-ignore
      accessor: f => f,
      Cell: ({ value }: Cell<License, License>) => (
        <div className="actions-column">
          <Link
            to={{
              pathname: `/license/add/${value.id}`,
            }}
            className="action-button"
          >
            <button type="button" className="btn">
              <EditIcon />
            </button>
          </Link>
          <button
            type="button"
            className="btn action-button"
            onClick={() => handleDelete(value.id)}
          >
            <DeleteIcon />
          </button>
        </div>
      ),
    },
  ];

  return (
    <>
      <ConfirmationModal
        itemName={t('ENTITY.LICENSE')}
        onClose={handleModalClose}
        show={showModal}
      />
      <div className="list-holder col-lg-12">
        <List
          filters={{}}
          initialSortBy={[{ id: 'organizationName', desc: false }]}
          id="licenses"
          ref={tableRef}
          data={licensesList}
          columns={columns}
        />
      </div>
    </>
  );
}
