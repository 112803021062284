import React, { useCallback, useEffect, useState } from 'react';
import { useTranslate } from '../../../_metronic/utils/customHooks';
import SeePass from './icons/SeePass';
import DoNotSeePass from './icons/DoNotSeePass';
import '../../../_metronic/_assets/sass/pages/login/login-1.scss';

export default function ResetPassword() {
  const t = useTranslate();

  const [inputValues, setInputValues] = useState({
    newPassword: '',
    confirmPassword: '',
  });
  const [currentPassword, setCurrentPassword] = useState('');

  const [dontSeePassword, setDontSeePassword] = useState({
    newPassword: true,
    confirmPassword: true,
    currentPassword: true,
  });
  const [showError, setShowError] = useState({
    newPassword: false,
    confirmPassword: false,
  });

  const handleOnChange = useCallback(
    event => {
      const { name, value } = event.target;
      if (value !== '') {
        setShowError({ ...showError, [name]: false });
      } else {
        setShowError({ ...showError, [name]: true });
      }
      setInputValues({ ...inputValues, [name]: value });
    },
    [inputValues],
  );

  const handleCurrentOnChange = useCallback(event => {
    const { value } = event.target;
    setCurrentPassword(value);
  }, []);

  useEffect(() => {
    if (
      inputValues.newPassword !== inputValues.confirmPassword &&
      inputValues.confirmPassword !== ''
    ) {
      setShowError({ newPassword: true, confirmPassword: true });
    }
  }, [inputValues]);

  const disablePaste = (e: any) => {
    e.preventDefault();
  };

  const handleChangePassword = useCallback(() => {}, []);
  return (
    <>
      <div className="kt-login__head" />
      <div className="kt-login__body">
        <div className="kt-login__form">
          <div className="kt-login__title">
            <h3>{t('login.reset_password')}</h3>
            <span className="kt-login__subtitle">{t('login.subtitle')}</span>
          </div>
          <div className="kt-login__input ">
            <div className="current-password">
              <label htmlFor="currentPassword">
                {t('userProfile.currentPassword')}
              </label>
              <input
                id="currentPassword"
                type="text"
                className={`form-control control-height ${
                  dontSeePassword.currentPassword ? 'active' : ''
                }`}
                name="currentPassword"
                value={currentPassword}
                placeholder={t('userProfile.currentPassword')}
                onChange={handleCurrentOnChange}
                autoComplete="off"
              />
              <button
                type="button"
                className="btn p-viewer"
                onClick={() =>
                  setDontSeePassword({
                    ...dontSeePassword,
                    currentPassword: !dontSeePassword.currentPassword,
                  })
                }
              >
                {dontSeePassword.currentPassword ? (
                  <DoNotSeePass />
                ) : (
                  <SeePass />
                )}
              </button>
            </div>
            <div className={`new-password ${showError.newPassword && 'error'}`}>
              <label htmlFor="newPassword">
                {t('userProfile.newPassword')}
              </label>
              <input
                id="newPassword"
                type="text"
                className={`form-control control-height ${
                  dontSeePassword.newPassword ? 'active' : ''
                }`}
                name="newPassword"
                value={inputValues.newPassword}
                placeholder={t('userProfile.newPassword')}
                onChange={handleOnChange}
                autoComplete="off"
              />
              <button
                type="button"
                className="btn p-viewer"
                onClick={() =>
                  setDontSeePassword({
                    ...dontSeePassword,
                    newPassword: !dontSeePassword.newPassword,
                  })
                }
              >
                {dontSeePassword.newPassword ? <DoNotSeePass /> : <SeePass />}
              </button>
            </div>
            <div
              className={`confirm-password ${
                showError.confirmPassword && 'error'
              }`}
            >
              <label htmlFor="confirmPassword">
                {t('userProfile.confirmPassword')}
              </label>
              <input
                id="confirmPassword"
                type="text"
                className={`form-control control-height ${
                  dontSeePassword.confirmPassword ? 'active' : ''
                }`}
                name="confirmPassword"
                value={inputValues.confirmPassword}
                placeholder={t('userProfile.confirmPassword')}
                onChange={handleOnChange}
                autoComplete="off"
                onPaste={disablePaste}
              />
              <button
                type="button"
                className="btn p-viewer"
                onClick={() =>
                  setDontSeePassword({
                    ...dontSeePassword,
                    confirmPassword: !dontSeePassword.confirmPassword,
                  })
                }
              >
                {dontSeePassword.confirmPassword ? (
                  <DoNotSeePass />
                ) : (
                  <SeePass />
                )}
              </button>
            </div>
            {showError.confirmPassword && showError.newPassword && (
              <span className="passwords-match col-lg-12">
                {t('userProfile.passwordsDontMatch')}
              </span>
            )}
            <div className="button-change_password">
              <button
                id="change_password"
                type="button"
                className="btn btn-primary change-password col-lg-5"
                onClick={() => handleChangePassword()}
              >
                {t('userProfile.changePassword')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
