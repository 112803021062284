import React, { useCallback, useLayoutEffect } from 'react';
import {
  DatePickerView,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import './datePicker.scss';

type DatePickerProps = {
  id: string;
  value: number | null;
  onChange?: (v: number | null) => void;
  openTo?: DatePickerView;
  minDate?: number | null;
  maxDate?: number | null;
  placeholder?: string;
  hasError?: boolean;
  disabled?: boolean;
  className?: string;
};

export default function DatePicker({
  value,
  onChange = () => null,
  id,
  openTo = 'date',
  minDate,
  maxDate,
  placeholder,
  hasError,
  disabled = false,
  className,
}: DatePickerProps) {
  useLayoutEffect(() => {
    const el = document.getElementById(id);
    if (el) {
      el.className = 'form-control';
      if (hasError) {
        el.classList.add('is-invalid');
      }
    }
  }, [id, hasError, disabled]);

  const handleChange = useCallback(
    (newValue: Date) => {
      const result = newValue
        ? new Date(newValue).setHours(0, 0, 0, 0).valueOf()
        : null;
      return onChange(result);
    },
    [onChange],
  );
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        disabled={disabled}
        disableToolbar
        placeholder={placeholder}
        openTo={openTo}
        views={['date']}
        className={className}
        format="dd/MM/yyyy"
        minDate={minDate}
        maxDate={maxDate}
        id={id}
        value={value ? new Date(value) : null}
        onChange={handleChange}
      />
    </MuiPickersUtilsProvider>
  );
}
