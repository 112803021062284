import React, { useEffect, useState } from 'react';
import { useDrop, useDrag, DragPreviewImage } from 'react-dnd';
import { Cell, Row } from 'react-table';

const labelToDataURL = (label: any): string => {
  const svg = `
  <svg
    width="600" height="48"
    xmlns="http://www.w3.org/2000/svg">
    <rect width="100%" height="100%" fill="#e7eff6" />
    <text
      x="6"
      y="24"
      style="font-size: 13px; font-weight: bold; font-family: Poppins, Arial, sans-serif"
      fill="#7a7b7c"
    >${label.toUpperCase()}</text>
  </svg>
  `;
  let dataUrl = 'data:image/svg+xml;base64,';
  dataUrl += btoa(svg);
  return dataUrl;
};

const DND_ITEM_TYPE = 'row';
type DragObject = {
  index: number;
};

type DraggableRowProps<T extends object> = {
  index: number;
  row: Row<T>;
  labelField: keyof T;
  className: string;
  isClickable: boolean;
  onRowClicked: (row: Row<T>, cell?: Cell<T>) => void;
  onRowMoved?: (dragIndex: number, hoverIndex: number) => void;
};

export default function DraggableRow<T extends object>(
  props: DraggableRowProps<T>,
) {
  const {
    index,
    row,
    labelField = 'label' as keyof T,
    className,
    isClickable,
    onRowClicked,
    onRowMoved,
  } = props;

  const ref = React.useRef<HTMLTableRowElement>(null);

  const [screenshot, setScreenshot] = useState<string>();
  useEffect(() => {
    if (onRowMoved) {
      setScreenshot(labelToDataURL(row.original[labelField]));
    }
  }, [onRowMoved, row.original]);

  const [{ handlerId }, drop] = useDrop({
    accept: DND_ITEM_TYPE,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragObject, monitor) {
      if (!ref.current || !onRowMoved) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      if (!clientOffset) return;
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      onRowMoved(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      // eslint-disable-next-line no-param-reassign
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: DND_ITEM_TYPE,
    item: { index, row, className },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;

  drag(drop(ref));

  return (
    <>
      {screenshot && <DragPreviewImage connect={preview} src={screenshot} />}
      <tr
        {...row.getRowProps()}
        className={className}
        style={{ opacity, cursor: onRowMoved ? 'move' : undefined }}
        ref={onRowMoved ? ref : undefined}
        data-handler-id={handlerId}
      >
        {row.cells.map(cell => (
          // eslint-disable-next-line
          <td
            {...cell.getCellProps()}
            className="kt-list-body-cell"
            style={{
              cursor: !onRowMoved && isClickable ? 'pointer' : undefined,
            }}
            onClick={() => onRowClicked(row, cell)}
          >
            {cell.render('Cell')}
          </td>
        ))}
      </tr>
    </>
  );
}
